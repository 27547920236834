export default defineStore('FilterStore', {
    state: () => ({
        types_of_article: [],
        years: [],
        countries: [],
        tags: [],
        cloudTags: [],
        types_of_activity: [],
        type_of_project: [],
        canton: [],
        departments: [],
        regions: [],
        themas: [],
        division: '',
        sortByName: '',
        sortBytCO2e: '',
        isFiltered: false,
        preFilteredCountry: '',
        textSearch: '',
    }),

    actions: {
        isFilter() {
            if (
                isArrayNotEmpty(this.types_of_article)
                || isArrayNotEmpty(this.years)
                || isArrayNotEmpty(this.countries)
                || isArrayNotEmpty(this.tags)
                || isArrayNotEmpty(this.departments)
                || isArrayNotEmpty(this.themas)
                || this.textSearch !== ''
            ) {
                this.isFiltered = true;
            } else {
                this.isFiltered = false;
            }
        },

        setTypesOfArticle(values) {
            this.types_of_article = values;
            this.isFilter();
        },

        setTypeOfProject(values) {
            this.type_of_project = values;
            this.isFilter();
        },

        setTypesOfActivity(values) {
            this.types_of_activity = values;
            this.isFilter();
        },

        setYears(values) {
            this.years = values;
            this.isFilter();
        },

        setCountries(values) {
            this.countries = values;
            this.isFilter();
        },

        setTags(values) {
            this.tags = values;
            this.isFilter();
        },

        setCloudTags(values) {
            this.cloudTags = values;
        },

        setDepartments(values) {
            this.departments = values;
            this.isFilter();
        },

        setThemas(values) {
            this.themas = values;
            this.isFilter();
        },

        setRegions(values) {
            this.regions = values;
            this.isFilter();
        },

        setDivision(value) {
            this.division = value;
            this.isFilter();
        },

        setCanton(values) {
            this.canton = values;
            this.isFilter();
        },

        setSortByName(value) {
            this.sortByName = value;
        },

        setSortBytCO2e(value) {
            this.sortBytCO2e = value;
        },

        setPrefilteredCountry(value) {
            this.preFilteredCountry = value;
        },

        setTextSearch(value) {
            this.textSearch = value;
            this.isFilter();
        },

        resetPreFilteredCountry() {
            this.preFilteredCountry = '';
        },

        resetData() {
            this.types_of_article = [];
            this.years = [];
            this.countries = [];
            this.tags = [];
            this.types_of_activity = [];
            this.type_of_project = [];
            this.canton = [];
            this.departments = [];
            this.regions = [];
            this.themas = [];
            this.division = '';
            this.sortByName = '';
            this.sortBytCO2e = '';
            this.isFiltered = false;
        },
    },

    getters: {
        getTypesOfArticle(state) {
            return state.types_of_article;
        },

        getTypesOfActivity(state) {
            return state.types_of_activity;
        },

        getTypeOfProject(state) {
            return state.type_of_project;
        },

        getYears(state) {
            return state.years;
        },

        getCountries(state) {
            return state.countries;
        },

        getTags(state) {
            return state.tags;
        },

        getCloudTags(state) {
            return state.cloudTags;
        },

        getCanton(state) {
            return state.canton;
        },

        getIsFilter(state) {
            return state.isFiltered;
        },

        getDepartments(state) {
            return state.departments;
        },

        getThemas(state) {
            return state.themas;
        },

        getRegions(state) {
            return state.regions;
        },

        getDivision(state) {
            return state.division;
        },

        getTextSearch(state) {
            return state.textSearch;
        },

        getSortByName(state) {
            return state.sortByName;
        },

        getSortBytCO2e(state) {
            return state.sortBytCO2e;
        },

        getPrefilteredCountry(state) {
            return state.preFilteredCountry;
        },
    },
});
