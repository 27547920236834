<template>
  <div class="error-layout is-petrol">
    <div class="error-layout__inner">
      <div class="error-layout__column">
        <div class=" error-layout__code">
          <p>{{ errorTitle }}</p>
          <span class="error-layout__code__title">{{ error.statusCode }}</span>
        </div>
      </div>

      <div class="error-layout__column">
        <div class="error-layout__content">
          <p class="error-layout__message">
            {{ error.statusCode === 404 ? messageText : error.message }}
          </p>

          <AtomButton
            class="error-layout__button"
            :text="backText"
            variant="inverted"
            @click="handleError()"
          />
        </div>
      </div>
      <img class="error-layout__image" src="./assets/images/error-img.png" alt="error image">
    </div>
  </div>
</template>

<script setup>

defineProps({
    error: {
        type: Object,
        default: () => {},
    },
});

const { locale } = useI18n();
const localePath = useLocalePath();

const messageText = computed(() => {
    switch (locale.value) {
    case 'en':
        return 'Sorry, this page could not be found.';
    case 'fr-ch':
        return 'Désolé, cette page ne peut être trouvée.';
    case 'it-ch':
        return 'Spiacente, questa pagina non può essere trovata.';
    default:
        return 'Entschuldigen Sie, diese Seite kann nicht gefunden werden.';
    }
});

const errorTitle = computed(() => {
    switch (locale) {
    case 'en':
        return 'Error';
    case 'fr-ch':
        return 'Erreur';
    case 'it-ch':
        return 'Errore';
    default:
        return 'Fehler';
    }
});

const backText = computed(() => {
    switch (locale) {
    case 'en':
        return 'To the homepage';
    case 'fr-ch':
        return 'Retour à la page d\'accueil';
    case 'it-ch':
        return 'Alla pagina principale';
    default:
        return 'Zur Startseite';
    }
});

const handleError = () => {
    const redirectPath = localePath('/');
    clearError({ redirect: redirectPath });
};
</script>

<style lang="scss">
.error-layout {
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color: var(--c-petrol-4);
    color: var(--c-petrol-1);
}

.error-layout__inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-left: 280px;
    @include mobile {
        justify-content: flex-start;
        padding-top: 120px;
        padding-left: 25px;
    }

}
.error-layout__code {
    padding-bottom: 20px;
    font-family: var(--f-family--black);
    font-size: 66px;
    line-height: 76px;
    @include mobile {
        font-size: 30px;
        line-height: 38px;
    }
}
.error-layout__code__title {
    color: var(--c-white);
}

.error-layout__message {
    color: var(--c-white);
    font-family: var(--f-family--thin);
    font-size: 17px;
    line-height: 27px;
}

.error-layout__button {
    display: flex;
    height: 56px;
    align-items: center;
    padding: 13px 40px;
    border-radius: 4px;
    margin-top: 20px;
    background: var(--c-white);
    color:  var(--c-petrol-1);
    font-family: var(--f-family--black);
    font-size: 16px;
}
.error-layout__image {
    position: absolute;
    right: 0;
    bottom: 0;
    @include mobile {
        max-width: 182px;
    }
}
</style>
