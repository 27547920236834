<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
/*
    New
*/
const { locale, defaultLocale } = useI18n();
const storyblokContentStore = useStoryblokContentStore();
const translatedSlugs = computed(() => storyblokContentStore.getTranslatedSlugs);

/*
  Dependencies
*/
const route = useRoute();
const storyblokApi = useStoryblokApi();
const { public: runtimeConfig } = useRuntimeConfig();
const { USE_STORYBLOK_DRAFT } = runtimeConfig;

/*
  Initialize variables
*/
const generalVersion = route.query._storyblok ? 'draft' : 'published';
const version = USE_STORYBLOK_DRAFT === 'true' ? 'draft' : generalVersion;

provide('storyblokVersion', version);
provide('isStoryblokPreview', generalVersion === 'draft');

/*
  Get settings from storyblok
*/
const { error, status: settingsStatus } = await useAsyncData(
    'storyblok-settings-data',
    () => storyblokApi.get(
        'cdn/stories/',
        {
            version,
            language: locale.value === defaultLocale ? '' : locale.value,
            starts_with: 'settings/',
            resolve_links: 'link',
        },
    ),
    {
        watch: [locale],
    },
);

if (error.value) {
    throw createError({
        statusCode: '500',
        statusMessage: 'Whoops! Failed to fetch settings from storyblok @App.vue',
    });
}

/*
  Set i18n params
*/
const setI18nParams = useSetI18nParams();
watch(() => translatedSlugs.value, (newValue) => {
    if (newValue) {
        setI18nParams(newValue);
    }
}, { immediate: true });

const activeRoute = computed(() => route.path);
const activityStore = useActivityStore();
const slugsMappingColor = getStoryblokSetting('mappingcolors', 'slugs_link');
const slugActivities = getStoryblokSetting('navigation', 'slug_activities');
const localePath = useLocalePath();
const colorSchema = ref('petrol');

const checkRoute = async () => {
    try {
        // if home set color to petrol
        let colorFound = false;
        if (activeRoute.value === `/${locale.value}` || activeRoute.value === '/') {
            colorSchema.value = 'petrol';
            return;
        }

        slugsMappingColor?.value?.forEach((item) => {
            const linkField = item.slug;
            const translatedSlug = linkField.story.alternates.find(
                (alternate) => alternate.lang === locale.value,
            )?.path;

            let slug = translatedSlug || linkField.story.slug;

            // Remove trailing slash if it exists
            slug = slug.endsWith('/') ? slug.slice(0, -1) : slug;

            // Add beginning slash if it does not exist
            slug = localePath(slug.startsWith('/') ? slug : `/${slug}`);

            if (activeRoute.value.includes(slug)) {
                colorSchema.value = item.color;
                colorFound = true;
            }
        });

        // if activities not in route reset activity filters
        if (!activeRoute.value.includes(slugActivities.value)) {
            activityStore.resetStates();
        }

        if (colorFound) return;

        colorSchema.value = 'petrol';
    } catch (e) {
        useSentryError(e);
    }
};

provide('colorSchema', colorSchema);

const filterStore = useFilterStore();
watch(() => [activeRoute.value, settingsStatus.value], ([newRouteValue, newSettingsValue]) => {
    if (newSettingsValue !== 'success') return;
    checkRoute();

    // reset filters after route change
    if (!newRouteValue.includes(slugActivities.value)) {
        filterStore.resetPreFilteredCountry();
    }
}, { immediate: true });

// Init Legally OK Cookie Banner
useLegallyCookieBanner();
</script>

<style lang="scss">
.main-content {
    background: var(--c-background);
    @include fluid('padding-bottom', 30px, 60px);
}

.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}

.page-leave-to {
    opacity: 0;
}

.page-enter-from {
    opacity: 0;
}
.isRtl {
    direction: rtl;
}
</style>
