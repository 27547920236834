// export const mainContent = ref(null);
// export const extraContent = ref(null);
// export const translatedSlugs = ref({});
// export const seoData = ref({});
// export const sbStoryId = ref(null);
export const sbVersion = ref(null);
export const isTextRtl = ref(false);
// export const firstPublishedAt = ref(null);
// export const pageLanguages = ref(null);
// export const defaultSlug = ref(null);
export const globalBreadcrumbs = ref(null);
export const mainContentNewsText = ref(null);

export const getNestedData = (keys, data) => keys.reduce((acc, key) => acc?.[key], data);

export const setMainContent = (sbResponse) => {
    if (!sbResponse) return;

    mainContent.value = getNestedData(
        [
            'data',
            'story',
            'content',
            'main_content',
        ],
        sbResponse,
    );
};

export const setTranslatedSlugs = (slugObj) => {
    if (!slugObj) return;

    translatedSlugs.value = slugObj;
};

export const setSEOValues = (seoToRender) => {
    if (!seoToRender) return;

    seoData.value = seoToRender;
};

export const setStoryId = (id) => {
    if (!id) return;

    sbStoryId.value = id;
};

export const setVersion = (ver) => {
    if (!ver) return;

    sbVersion.value = ver;
};

export const setIsTextRtl = (isRtl) => {
    isTextRtl.value = isRtl;
};

export const setFirstPublishedAt = (publishedAt) => {
    firstPublishedAt.value = publishedAt;
};

export const setExtraContent = (sbResponse) => {
    if (!sbResponse) return;

    extraContent.value = getNestedData(
        [
            'data',
            'story',
            'content',
        ],
        sbResponse,
    );
};

export const setPageLanguages = (languages) => {
    pageLanguages.value = languages;
};

export const setDefaultSlug = (slug) => {
    defaultSlug.value = slug;
};

export const setGlobalBreadcrumbs = (breadcrumbs) => {
    globalBreadcrumbs.value = breadcrumbs;
};

export const setMainContentNewsText = (newsText) => {
    mainContentNewsText.value = newsText;
};
