<template>
  <component
    :is="htmlTag"
    class="atom-button"
    type="button"
    :disabled="isDisabled"
    :class="[
      `is-color-${colorSchema}`,
      `is-${variant}`,
      `is-padding-${padding}`,
    ]"
    @click="onClick"
  >
    <span
      class="atom-button__text"
    >{{ text }}</span>
    <IonIcon v-if="icon" :icon-name="icon" />
  </component>
</template>

<script setup>
const props = defineProps({
    /*
          Text
      */
    text: {
        type: String,
        default: 'Call to action',
        validator: (value) => value.length > 0,
    },

    /*
          Colors
      */
    colorSchema: {
        type: String,
        default: null,
        validator: (value) => [
            '',
            'petrol',
            'red',
            'blue',
            'orange',
            'green',
            'coral',
            'mustard',
        ].includes(value) || value === null,
    },

    colorCode: {
        type: String,
        default: '',
    },

    colorCodeHover: {
        type: String,
        default: '',
    },

    /*
          Variants
      */
    variant: {
        type: String,
        default: 'primary',
        validator: (value) => [
            'primary', 'inverted', 'outline', 'real-outline',
        ].includes(value),
    },

    isDisabled: {
        type: Boolean,
        default: false,
    },

    padding: {
        type: String,
        default: 'default',
        validator: (value) => ['medium', 'default'].includes(value),
    },

    /*
          Functionality
      */
    callback: {
        type: Function,
        default: () => {},
    },

    htmlTag: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'div',
        ].includes(value),
    },

    /*
        Icon
    */
    icon: {
        type: String,
        default: '',
    },
});

const onClick = () => {
    if (props.isDisabled) return;
    props.callback();
};

const colorTopLevel = inject('colorSchema');

const colorSchemaMain = computed(() => (
    props.colorSchema
        ? `var(--c-${props.colorSchema}-6)`
        : `var(--c-${colorTopLevel?.value}-6)`
));

const colorSchemaHover = computed(() => (
    props.colorSchema
        ? `var(--c-${props.colorSchema}-5)`
        : `var(--c-${colorTopLevel?.value}-5)`
));

const colorMain = computed(() => (
    props.colorCode
        ? props.colorCode
        : colorSchemaMain.value
));

const colorHover = computed(() => (
    props.colorCodeHover
        ? props.colorCodeHover
        : colorSchemaHover.value
));
</script>

<style lang="scss">
.atom-button__text {
    z-index: 1;
}

.atom-button {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    padding: 13px 26px;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    color: var(--c-white);
    column-gap: 40px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--button);
    outline: none;
    transition: background-color 0.2s $EASE_IN_OUT--SINE, color 0.2s $EASE_IN_OUT--SINE;

    .block-form-login__form & {
        padding: 13px 70px;
    }

    @include mobile {
        height: 47px;
        padding: 0px 35px;
    }

    span {
        padding-top: 1px;
    }

    &.is-primary {
        background: v-bind(colorMain);
        color: var(--c-white);
    }

    &.is-inverted {
        background: var(--c-white);
        color: v-bind(colorMain);
    }

    &.is-primary,
    &.is-inverted  {
        &:hover {
            background: v-bind(colorHover);
            color: var(--c-white);
        }
    }

    &.is-outline {
        border: 2px solid v-bind(colorMain);
        background: var(--c-white);
        color: v-bind(colorMain);

        &:hover {
            background: 2px solid v-bind(colorHover);
            color: v-bind(colorHover);
        }
    }

    &.is-real-outline {
        border: 2px solid v-bind(colorMain);
        color: v-bind(colorMain);

        &:hover {
            border: 2px solid v-bind(colorSchemaHover);
            background: v-bind(colorSchemaHover);
            color: var(--c-white);
        }
    }

    &.is-padding-medium {
        padding: 13px 63px;
    }

    .block-calculation-next-steps & {
        justify-content: flex-start;
    }

    &.is-next-steps  {
        background: v-bind(colorMain);
    }
}

</style>
