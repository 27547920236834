const getNestedData = (keys, data) => keys.reduce((acc, key) => acc?.[key], data);

export default defineStore('StoryblokContentStore', {
    state: () => ({
        mainContent: null,
        extraContent: null,
        translatedSlugs: {},
        seoData: {},
        sbStoryId: null,
        sbVersion: null,
        isTextRtl: false,
        firstPublishedAt: null,
        pageLanguages: null,
        defaultSlug: null,
        overviewPath: '/news',
    }),

    actions: {
        setMainContent(data) {
            if (!data) return;

            this.mainContent = getNestedData(
                [
                    'data',
                    'story',
                    'content',
                    'main_content',
                ],
                data,
            );
        },

        setExtraContent(data) {
            if (!data) return;

            this.extraContent = getNestedData(
                [
                    'data',
                    'story',
                    'content',
                ],
                data,
            );
        },

        setTranslatedSlugs(data) {
            if (!data) return;

            this.translatedSlugs = data;
        },

        setSEOValues(data) {
            if (!data) return;

            this.seoData = data;
        },

        setStoryId(data) {
            if (!data) return;

            this.sbStoryId = data;
        },

        setVersion(data) {
            if (!data) return;

            this.sbVersion = data;
        },

        setIsTextRtl(data) {
            if (!data) return;

            this.isTextRtl = data;
        },

        setFirstPublishedAt(data) {
            if (!data) return;

            this.firstPublishedAt = data;
        },

        setPageLanguages(data) {
            if (!data) return;

            this.pageLanguages = data;
        },

        setDefaultSlug(data) {
            if (!data) return;

            this.defaultSlug = data;
        },

        setOverviewPath(data) {
            if (!data) return;

            this.overviewPath = data;
        },
    },

    getters: {
        getMainContent(state) {
            return state.mainContent;
        },

        getExtraContent(state) {
            return state.extraContent;
        },

        getTranslatedSlugs(state) {
            return state.translatedSlugs;
        },

        getSEOValues(state) {
            return state.seoData;
        },

        getStoryId(state) {
            return state.sbStoryId;
        },

        getVersion(state) {
            return state.sbVersion;
        },

        getIsTextRtl(state) {
            return state.isTextRtl;
        },

        getFirstPublishedAt(state) {
            return state.firstPublishedAt;
        },

        getPageLanguages(state) {
            return state.pageLanguages;
        },

        getDefaultSlug(state) {
            return state.defaultSlug;
        },

        getOverviewPath(state) {
            return state.overviewPath;
        },
    },
});
