const defaultOptions = {
    useThrow: false,
    payload: null,
    response: null,
};

export default (err, options = {}) => {
    if (process.server) return;

    const mergedOptions = { ...defaultOptions, ...options };
    const { useThrow, payload, response } = mergedOptions;

    const config = useRuntimeConfig();
    const { $sentryCaptureException } = useNuxtApp();

    if (config?.public?.IS_STORYBOOK === 'true' || config?.public?.IS_STORYBOOK === true) {
        return;
    }

    if (config?.public?.USE_SENTRY === 'true' && $sentryCaptureException) {
        $sentryCaptureException(err, {
            extra: {
                payload,
                response,
                errorObject: {
                    ...err,
                    data: err.data ? JSON.stringify(err.data) : undefined,
                },
            },
        });
    } else {
        console.error(err, options);
    }

    if (useThrow) {
        throw createError({
            statusCode: '500',
            statusMessage: err.message,
        });
    }
};
