/*
  Redirects
  For authenticated and public users
*/

/* eslint-disable consistent-return */
export default defineNuxtRouteMiddleware(async () => {
    const cookie = useCookie('token');
    const isValid = isValidUid(cookie.value);
    const authStore = useAuthStore();
    const isAuthPage = authStore.getIsAuthPage;

    if (isAuthPage && !isValid) {
        return navigateTo('/');
    }
});
