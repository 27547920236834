export default defineStore('auth', {
    state: () => ({
        authenticated: false,
        loading: false,
        isAuthPage: false,
    }),

    actions: {
        async authenticateUser(username, password, loginData) {
            if (username === loginData.username && password === loginData.password) {
                const token = useCookie('token', { maxAge: 60 * 60 * 24 });
                const data = {
                    value: {
                        token: getUid(),
                    },
                };

                token.value = data?.value?.token;
                this.authenticated = true;
            }

            return this.authenticated;
        },

        adminLogin() {
            const token = useCookie('token', { maxAge: 60 * 60 * 24 });
            const data = {
                value: {
                    token: getUid(),
                },
            };
            token.value = data?.value?.token;
            this.authenticated = true;
        },

        logUserOut() {
            const token = useCookie('token', { maxAge: 60 * 60 * 24 });
            this.authenticated = false;
            token.value = null;

            if (this.isAuthPage) {
                this.isAuthPage = false;
                navigateTo('/');
            }
        },

        setIsAuthPage(isProtected) {
            this.isAuthPage = isProtected;
        },

    },

    getters: {
        getIsAuthenticated() {
            const cookie = useCookie('token');
            const isValid = isValidUid(cookie.value);

            return isValid;
        },

        getIsAuthPage() {
            return this.isAuthPage;
        },
    },
});
