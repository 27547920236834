export default defineStore('ActivityStore', {
    state: () => ({
        storedPageIndex: 6,
        storedIndex: null,
        currentLayout: 'default',
    }),

    actions: {
        setStoredPageIndex(index) {
            this.storedPageIndex = index;
        },

        setStoredIndex(uuid) {
            this.storedIndex = uuid;
        },

        setCurrentLayout(layout) {
            this.currentLayout = layout;
        },

        resetStates() {
            this.storedPageIndex = 6;
            this.storedIndex = null;
        },
    },

    getters: {
        getStoredPageIndex(state) {
            return state.storedPageIndex;
        },

        getStoredIndex(state) {
            return state.storedIndex;
        },

        getCurrentLayout(state) {
            return state.currentLayout;
        },
    },
});
