<template>
  <component
    :is="`Svgo${toUpperCamelCase(iconName)}`"
    :key="uniqueKey"
    :style="`
        --color: ${color};
        --color--1: ${color1};
        --color--4: ${color4};
        --color--8: ${color8};
        --color--scheme: ${colorScheme};
        --color--icon: ${colorIcon};
        --color--schema: ${colorSchema};
        --color--schema-hover: ${colorSchemaHover};
        --color--palette: ${colorPalette};
    `"
    class="ion-icon"
  />
</template>

<script setup>
const props = defineProps({
    iconName: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    colorSchema: {
        type: String,
        default: 'orange',
    },

    color: {
        type: String,
        default: '',
    },
});

const {
    color,
} = toRefs(props);

/*
  Rerender the asnyc child component when the icon name changes.
*/
const uniqueKey = ref(getUid());

watch(() => props.name, () => {
    uniqueKey.value = getUid();
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const color8 = computed(() => `var(--c-${colorTopLevel.value}-8)`);

const calculatorStore = useCalculatorStore();
const colorScheme = computed(() => calculatorStore.getColorScheme);

const colorIcon = `var(--c-${colorScheme.value}-4)`;
const colorSchema = computed(() => (props.colorSchema ? `var(--c-${props.colorSchema}-1)` : 'var(--c-orange-1)'));
const colorSchemaHover = computed(() => (props.colorSchema ? `var(--c-${props.colorSchema}-4)` : 'var(--c-orange-4)'));

const colorPalette = computed(() => (props.colorSchema === 'white'
    ? `var(--c-${props.colorSchema})` : `var(--c-${props.colorSchema}-4)`));
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */

.ion-icon {
    width: 20px;
    height: 20px;

    path {
        transition: fill 0.3s ease;
    }

    &.storyblok-info-programm__section-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        aspect-ratio: 25/21;

        @include mobile {
            width: inherit;
            min-height: 147px;
        }
    }

    &.storyblok-info-programm__company-icon,
    &.storyblok-info-programm__person-icon{
        width: 40px;
        height: 40px;
    }

    &.block-header__logo {
        width: 100px;
        height: 60px;

        path {
            fill:var(--color--1);

            &:first-child {
                fill: var(--color--8);

            }
        }

        // .klik-letter {
        // }

        @include tablet {
            width: 108px;
            height: 42px;
        }

        @include mobile {
            width: 46px;
            height: 18px;
        }
    }

    .storyblok-teaser-box__link & {
        path {
            stroke: var(--c-white) !important;
        }
    }

    .block-hero & ,
    .storyblok-info-box &,
    .block-slider-image &,
    .block-slider-hero &,
    .atom-item-slider-hero & {
        path {
            fill: none;
            stroke: var(--c-white);
        }
    }

    .block-download-hub__filter-primary-program & {
        path {
            stroke: var(--department-color);
        }
    }

    &.block-header__icon  {
        width: 22px;
        height: 22px;
    }

    &.atom-item-slider-hero__icon {
        width: 22px;
        height: 22px;
    }

    &.atom-link__icon {
        path {
            stroke:var(--color--4);
        }
    }

    &.block-breadcrumbs__icon {
        path {
            stroke:var(--color--4);
        }
    }

    &.atom-input-multiselect__icon {
        path {
            stroke:var(--color--4);
        }
    }

    &.atom-input-multiselect__icon-close {
        path {
            fill:var(--color--4);
        }
    }

    .block-slider-hero__controls & {
        path {
            fill: none;
            stroke: var(--c-blue-4);
        }
    }

    .block-slider-image__slider & {
        path {
            stroke: var(--color--1);
        }
    }

    .layout-news-hero & {
        path {
            stroke: var(--color--4);
        }

        rect {
            stroke: var(--color--4);
        }

        &.block-header__logo {
            path {
                stroke: none;
            }
        }
    }

    .block-header__links &, .block-header__navigation & {
        width: 22px;
        height: 22px;

        path {
            fill:var(--color--1);
        }

        .klik-letter {
            fill:var(--color--8);
        }
    }

    .block-flyout__content-main & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-hero-links__link & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments__department-label & {
        min-width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-international__region-label & {
        width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .atom-input-radio-calc__info & {
        width: 22px;
        height: 22px;

        path, circle {
            stroke:var(--color--icon);
        }

        .point {
            fill:var(--color--icon);
        }
    }

    .block-calculation-item__info & {
        width: 22px;
        height: 22px;

        path, circle {
            stroke:var(--color--icon);
        }

        .point {
            fill:var(--color--icon);
        }
    }

    .block-flyout-mobile__bottom & {
        width: 40px;
        height: 40px;

        path, circle {
            stroke:var(--color--1);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-nav-links-mobile__main-link & {
        width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-flyout-mobile__content & {
        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments-mobile {
        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments-mobile__department-label & {
        min-width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments-mobile__main-link & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-international-mobile__main-link & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .layout-person__content & {
        width: 23px;
        height: 23px;
    }

    .block-contribution-calculator__info & {
        width: 22px;
        height: 22px;

        path, circle {
            stroke: var(--c-white);
        }

        .point {
            fill: var(--c-white);
        }
    }

    .block-navigation-language__active-lang & {
        width: 20px;
        height: 20px;

        path {
            fill: none;
        }
    }

    &.block_teaser__icon {
        path {
            stroke:var(--color--4);
        }
    }

    &.block-flyout-mobile__logo-home {
        width: 40px;
        height: 40px;

        path {
            stroke:var(--color--1);
        }

        @include mobile{
            width: 22px;
            height: 22px;
        }
    }

    &.block-header__logo-home {
        path {
            fill: var(--c-white);
            stroke:var(--color--1);
        }
    }

    &.block-navigation-language__icon {
        path {
            stroke:var(--color--1);
        }
    }

    &.block-service-menu__item-icon {
        path {
            stroke:var(--color--4);
        }

        circle {
            stroke:var(--color--4);
        }
    }

    .block-overlay-person & {
        path {
            fill:var(--color--1);
        }
    }

    .layout-news__hero-article-details-share & {
        width: 25px;
        height: 25px;
    }

    .layout-activity__hero-article-details-share & {
        width: 25px;
        height: 25px;
    }

    .block-map-international__slide-link & {
        path {
            stroke: var(--c-coral-1);
        }
    }

    .block-map-international__controls & {
        path {
            stroke: var(--c-coral-4);
        }
    }

    .block-map-international__close & {
        path {
            fill: var(--c-coral-4);
        }
    }

    .block-map-international__map-controls & {
        width: 36px;
        height: 36px;
    }

    .block-flyout-regions-mobile__title & {
        width: 22px;
        height: 22px;
    }

    .block-contribution-calculator__controls & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-map-international__pinch-button & {
        width: 42px;
        height: 28px;

        path {
            stroke: var(--c-white);
        }

        .lock {
            fill: var(--c-white);
            stroke: none;
        }
    }

    .block-flyout-departments-mobile__title & {
        width: 22px;
        height: 22px;
    }

    .has-error & {
        path {
            fill: var(--c-error) !important;
        }
    }

    .block-download-hub & {
        min-width: 22px;
        height: 22px;
    }

    &.block-download-hub__filter-primary-country-icon {
        path {
            stroke: var(--c-coral-1);
        }

        .is-active & {
            path {
                stroke: var(--c-white);
            }
        }
    }

    .block-download-hub__search & {
        width: 22px;
        height: 22px;
    }

    .block-item-calculator__title & {
        min-width: 22px;
        height: 22px;
    }

    .block-contribution-calculator__header-controls & {
        width: 32px;
        height: 32px;

        path {
            stroke: var(--c-white);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-slider-enumeration__controls & {
        path {
            stroke:var(--color--4);
        }
    }

    &.block-download-hub__search-title-icon-magnifying {
        width: 29px;
        height: 29px;

        @include mobile {
            width: 23px;
            height: 23px;
        }
    }

    &.block-download-hub__search-title-icon {
        width: 22px;
        height: 22px;
    }

    &.block-download-hub__filter-primary-department-icon {

        path {
            stroke:var(--color) !important;
        }
    }

    &.block-download-hub__filter-secondary-department-icon {

        path {
            stroke:var(--color) !important;
        }

        .is-active & {
            path {
                stroke: var(--c-white) !important;
            }
        }
    }

    &.block-download-hub__filter-primary-program-icon {
        .is-active & {
            path {
                stroke: var(--c-white);
            }
        }
    }

    .block-download-hub__select & {

        path {
            stroke:var(--color);
        }
    }

    .atom-item-download-card-mini & {

        path {
            stroke:var(--color);
        }
    }

    .block-item-info-programm__link &  {

        path {
            stroke:var(--color--schema);
        }
        .is-active & {
            path {
                stroke:var(--color--schema-hover);
            }
        }
    }

    .block-calculation__error & {
        width: 22px;
        height: 22px;

        .point {
            fill: var(--c-error);
        }

        path {
            stroke: var(--c-error);
        }
    }

    &.block-calculation__error-close {

        path {
            fill: var(--c-error);
            stroke: none;
        }
    }

    &.block-flyout-regions__title-icon  {
        width: 36px;
        height: 36px;
    }

    &.block-flyout-departments__title-icon  {
        width: 36px;
        height: 36px;
    }

    .block-item-color__title & {
        width: 36px;
        height: 36px;

        path {
            stroke:var(--color--palette);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-item-color__title-closed & {
        width: 36px;
        height: 36px;

        path {
            stroke: var(--c-white);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-item-color__controls & {
        width: 36px;
        height: 36px;

        path {
            stroke: var(--c-white);

            .is-dark & {
                stroke: var(--c-black);
            }
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-item-color__content-details-left-wrapper-item-icon & {
        path {
            stroke:var(--color--palette);
        }
    }

    .block-header__navigation-login & {
        path {
            fill: var(--c-white);
            stroke:var(--color--4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }

        circle {
            fill: var(--c-white);
            stroke:var(--color--4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }
    }

    .block-header__navigation-login:hover & {
        path {
            fill:var(--color--4);
            stroke: var(--c-white);
        }

        circle {
            fill:var(--color--4);
            stroke: var(--c-white);
        }
    }

    .block-header__navigation-login-mobile & {
        path {
            fill: var(--c-white);
            stroke:var(--color--4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }

        circle {
            fill: var(--c-white);
            stroke:var(--color--4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }
    }

    .block-teaser-news__controls & {
        width: 25px;
        height: 25px;

        path {
            stroke:var(--color--1);
        }
    }

    .layout-news-smilararticles__controls & {
        width: 25px;
        height: 25px;

        path {
            stroke:var(--color--1);
        }
    }

    &.block-news-filtered__icon  {
        width: 25px;
        height: 25px;

        path {
            stroke:var(--color--4);
        }
    }

    &.block-image-gallery__button-icon {
        path {
            stroke:var(--color--4);
        }
    }

    .storyblok-teaser-pdf__header__icons & {
        path {
            stroke: var(--color--4);
        }

        ellipse {
            stroke: var(--color--4);
        }

        circle {
            stroke: var(--color--4);
        }
    }

    .atom-item-accordion__close-icon & {
        path {
            stroke: var(--color--4);
        }
    }

    .block-viewer-pdf__swiper__nav & {
        path {
            stroke: var(--color--4);
        }
    }

    .speaker-line {
        fill: var(--color--4);
    }

    &.block_teaser_video__tag-icon {
        width: 30px;
        height: 30px;
    }
}
</style>
